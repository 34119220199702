/** @format */

import React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles';
import { Button, H2, Dialog, Spinner, InputGroup, ButtonGroup } from '@blueprintjs/core';

import { get, post } from '../requests';
import DemoPage, { DEFAULT_DEMO } from './DemoApp';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  creationForm: {
    width: 400,
    padding: 24,
    boxShadow: '1px 1px 10px -5px rgba(0,0,0,0.75)',
    border: '1px solid #FCFCFC',
    height: '100vh',
    overflow: 'auto',
  },
  createButton: {
    width: '100%',
  },
  demoItem: {
    padding: `4px 0`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: 4,
  },
  demoName: {
    fontSize: 16,
  },
  demoActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContents: 'space-between',
  },
  demoEditorHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  demoEditorBackBtn: {
    margin: '0 0 10px',
  },
  preview: {
    width: `calc(100% - 400px)`,
    marginLeft: 30,
    marginRight: 30,
    height: 'calc(100vh - 60px)',
    boxShadow: '1px 1px 22px 0px rgba(0,0,0,0.75)',
  },
  newDemoModalBody: {
    padding: 20,
  },
  newDemoInput: {
    marginBottom: 12,
  },
  newDemoFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: `0 20px`,
  },
  sectionHeader: {
    fontWeight: 800,
    marginBottom: 12,
    fontSize: 18,
  },
  sectionHeaderWithButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionContainer: {
    backgroundColor: '#EFEFEF',
    padding: 12,
    borderRadius: 4,
    marginBottom: 12,
  },
  tabConfig: {
    display: 'flex',
    alignItems: 'center',
  },
  inputNoLabel: {
    marginTop: 18,
    marginLeft: 12,
  },
});

type Props = { isReportBuilder?: boolean } & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  WithStyles<typeof styles>;

export type TabData = {
  tabName: string;
  entityType: 'Analytics Hub' | 'Explore';
  entityAuthId: string;
};

export type SectionData = {
  sectionName: string;
  tabs: TabData[];
};

export type DemoState = {
  name?: string;
  url?: string;
  primaryColor?: string;
  actionColor?: string;
  textPrimaryColor?: string;
  textActionColor?: string;
  logoUrl?: string;
  companyName?: string;
  _id?: string;
  sections?: SectionData[];
};

type State = {
  currentDemos?: DemoState[];
  selectedDemo?: DemoState;
  newDemoInfo?: DemoState[];
  createNewDemoModalOpen?: boolean;
  newDemoName?: string;
  newDemoUrl?: string;
};

class DashboardPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    get('https://embeddo-custom-demo-040db5305558.herokuapp.com/api/list-demos', null).then(
      (demos) => {
        this.setState({ currentDemos: demos });
      },
    );

    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.creationForm}>
          {this.state.selectedDemo === undefined
            ? this.renderDemosOverview()
            : this.renderDemoEditor()}
        </div>
        <div className={classes.preview}>{this.renderPreview()}</div>
        {this.renderNewDemoModal()}
      </div>
    );
  }

  renderPreview = () => {
    const previewDemoState = {
      ...DEFAULT_DEMO,
      ...this.state.selectedDemo,
    };

    return (
      <DemoPage demo={previewDemoState}>
        <explo-dashboard
          dash-user-group-token={
            'BaQ1yyg18o:9a40bb523dc354d4e2629d1d884af8d1eb0b71961df8b14a538c048354d59fe6'
          }
          environment="production"
        />
      </DemoPage>
    );
  };

  isDemoNameTaken = (name?: string) => {
    const { currentDemos } = this.state;

    if (!name || !currentDemos) return false;

    const currentDemoNames = currentDemos.map((demo) => demo.name);

    return currentDemoNames.findIndex((demoName) => demoName === name) >= 0;
  };

  isUrlTaken = (url?: string) => {
    const { currentDemos } = this.state;

    if (!url || !currentDemos) return false;

    const currentUrls = currentDemos.map((demo) => demo.url);

    return currentUrls.findIndex((demoUrl) => url === demoUrl) >= 0;
  };

  renderNewDemoModal = () => {
    const { classes } = this.props;

    const nameIsTaken = this.isDemoNameTaken(this.state.newDemoName);
    const urlIsTaken = this.isUrlTaken(this.state.newDemoUrl);

    const isNotReadyToCreate =
      !this.state.newDemoName || !this.state.newDemoUrl || nameIsTaken || urlIsTaken;

    return (
      <Dialog
        title="Create New Demo"
        isOpen={this.state.createNewDemoModalOpen}
        onClose={() => this.setState({ createNewDemoModalOpen: false })}>
        <div className={classes.newDemoModalBody}>
          <div className={classes.newDemoInput}>
            <label className="bp4-label">
              Demo Name
              <InputGroup
                intent={nameIsTaken ? 'danger' : 'none'}
                placeholder="Enter a name"
                value={this.state.newDemoName || ''}
                onChange={(e) => this.setState({ newDemoName: e.target.value })}
              />
            </label>
          </div>
          <div className={classes.newDemoInput}>
            <label className="bp4-label">
              Demo URL (no spaces)
              <InputGroup
                intent={urlIsTaken ? 'danger' : 'none'}
                placeholder="Enter a URL Tag"
                value={this.state.newDemoUrl || ''}
                onChange={(e) => this.setState({ newDemoUrl: e.target.value })}
              />
            </label>
          </div>
        </div>
        <div className={classes.newDemoFooter}>
          <Button
            disabled={isNotReadyToCreate}
            intent="primary"
            onClick={() => !isNotReadyToCreate && this.createNewDemo()}>
            Create
          </Button>
        </div>
      </Dialog>
    );
  };

  renderDemosOverview = () => {
    const { classes } = this.props;
    return (
      <>
        <H2>Demos</H2>
        <Button
          className={classes.createButton}
          icon="plus"
          intent="primary"
          onClick={() =>
            this.setState({
              createNewDemoModalOpen: true,
            })
          }>
          Create New Demo
        </Button>
        {this.state.currentDemos === undefined ? (
          <Spinner />
        ) : this.state.currentDemos.length === 0 ? (
          <div>No Saved Demos</div>
        ) : (
          <>
            {this.state.currentDemos.map((demo) => (
              <div className={classes.demoItem} key={demo.url}>
                <div className={classes.demoName}>{demo.name}</div>
                <div className={classes.demoActions}>
                  <Button
                    icon="edit"
                    minimal
                    onClick={() =>
                      this.setState({
                        selectedDemo: { ...demo },
                      })
                    }
                  />
                  <Button
                    icon="share"
                    minimal
                    onClick={() =>
                      window.open(
                        `/${demo.url}`,
                        '_blank', // <- This is what makes it open in a new window.
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  renderDemoEditor = () => {
    const { classes } = this.props;
    const { currentDemos, selectedDemo } = this.state;

    const oldDemoState = currentDemos?.find((demo) => demo._id === selectedDemo?._id);

    const nameIsTaken =
      selectedDemo?.name !== oldDemoState?.name && this.isDemoNameTaken(selectedDemo?.name);
    const urlIsTaken =
      selectedDemo?.url !== oldDemoState?.url && this.isUrlTaken(selectedDemo?.url);

    return (
      <>
        <div className={classes.demoEditorHeader}>
          <Button
            className={classes.demoEditorBackBtn}
            icon="arrow-left"
            minimal
            onClick={() =>
              this.setState({
                selectedDemo: undefined,
              })
            }
          />
          <H2>{selectedDemo?.name} </H2>
        </div>
        <div className={classes.sectionHeader}>Admin</div>

        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Demo Name
            <InputGroup
              intent={nameIsTaken ? 'danger' : 'none'}
              placeholder="Enter a name"
              value={selectedDemo?.name || ''}
              onChange={(e) =>
                this.setState({ selectedDemo: { ...selectedDemo, name: e.target.value } })
              }
            />
          </label>
        </div>
        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Demo URL
            <InputGroup
              intent={urlIsTaken ? 'danger' : 'none'}
              placeholder="Enter a URL tag"
              value={selectedDemo?.url || ''}
              onChange={(e) =>
                this.setState({ selectedDemo: { ...selectedDemo, url: e.target.value } })
              }
            />
          </label>
        </div>
        <div className={classes.sectionHeader}>Styles</div>

        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Primary Color
            <InputGroup
              intent={urlIsTaken ? 'danger' : 'none'}
              placeholder="Enter a color Hex with the #"
              value={selectedDemo?.primaryColor || ''}
              onChange={(e) =>
                this.setState({ selectedDemo: { ...selectedDemo, primaryColor: e.target.value } })
              }
            />
          </label>
        </div>
        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Action Color
            <InputGroup
              intent={urlIsTaken ? 'danger' : 'none'}
              placeholder="Enter a color Hex with the #"
              value={selectedDemo?.actionColor || ''}
              onChange={(e) =>
                this.setState({ selectedDemo: { ...selectedDemo, actionColor: e.target.value } })
              }
            />
          </label>
        </div>
        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Text Primary Color
            <InputGroup
              intent={urlIsTaken ? 'danger' : 'none'}
              placeholder="Enter a color Hex with the #"
              value={selectedDemo?.textPrimaryColor || ''}
              onChange={(e) =>
                this.setState({
                  selectedDemo: { ...selectedDemo, textPrimaryColor: e.target.value },
                })
              }
            />
          </label>
        </div>
        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Text Action Color
            <InputGroup
              intent={urlIsTaken ? 'danger' : 'none'}
              placeholder="Enter a color Hex with the #"
              value={selectedDemo?.textActionColor || ''}
              onChange={(e) =>
                this.setState({
                  selectedDemo: { ...selectedDemo, textActionColor: e.target.value },
                })
              }
            />
          </label>
        </div>
        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Company Name
            <InputGroup
              intent={urlIsTaken ? 'danger' : 'none'}
              placeholder="Enter a Company Name"
              value={selectedDemo?.companyName || ''}
              onChange={(e) =>
                this.setState({ selectedDemo: { ...selectedDemo, companyName: e.target.value } })
              }
            />
          </label>
        </div>
        <div className={classes.newDemoInput}>
          <label className="bp4-label">
            Logo URL
            <InputGroup
              intent={urlIsTaken ? 'danger' : 'none'}
              placeholder="Enter a Logo URL for the navbar"
              value={selectedDemo?.logoUrl || ''}
              onChange={(e) =>
                this.setState({ selectedDemo: { ...selectedDemo, logoUrl: e.target.value } })
              }
            />
          </label>
        </div>
        <div className={cx(classes.sectionHeader, classes.sectionHeaderWithButton)}>
          <div>Sections</div>
          <Button
            minimal
            intent="none"
            onClick={() => {
              this.setState({
                selectedDemo: {
                  ...selectedDemo,
                  sections: [
                    ...(selectedDemo?.sections || []),
                    { sectionName: 'New Section', tabs: [] },
                  ],
                },
              });
            }}
            icon="plus"></Button>
        </div>
        <div>
          {selectedDemo?.sections?.map((section, sectionIndex) => (
            <div className={classes.sectionContainer} key={`section-editor-${sectionIndex}`}>
              <div className={classes.newDemoInput}>
                <label className="bp4-label">
                  Section Name
                  <InputGroup
                    placeholder="Section Name"
                    value={section.sectionName || ''}
                    onChange={(e) => {
                      const newSections = [...(selectedDemo.sections || [])];
                      newSections[sectionIndex].sectionName = e.target.value;
                      this.setState({
                        selectedDemo: {
                          ...selectedDemo,
                          sections: newSections,
                        },
                      });
                    }}
                  />
                </label>
              </div>
              {section.tabs.map((tab, tabIndex) => (
                <div
                  className={cx(classes.newDemoInput, classes.tabConfig)}
                  key={`tab-editor-${tabIndex}`}>
                  <label className="bp4-label" style={{ marginRight: 12 }}>
                    Tab Name
                    <InputGroup
                      placeholder="Tab Name"
                      value={tab.tabName || ''}
                      onChange={(e) => {
                        const newTabs = [...section.tabs];
                        newTabs[tabIndex].tabName = e.target.value;
                        const newSections = [...(selectedDemo.sections || [])];
                        newSections[sectionIndex].tabs = newTabs;

                        this.setState({
                          selectedDemo: {
                            ...selectedDemo,
                            sections: newSections,
                          },
                        });
                      }}
                    />
                  </label>
                  <label className="bp4-label">
                    Tab Auth Key
                    <InputGroup
                      placeholder="Auth Key"
                      value={tab.entityAuthId || ''}
                      onChange={(e) => {
                        const newTabs = [...section.tabs];
                        newTabs[tabIndex].entityAuthId = e.target.value;
                        const newSections = [...(selectedDemo.sections || [])];
                        newSections[sectionIndex].tabs = newTabs;

                        this.setState({
                          selectedDemo: {
                            ...selectedDemo,
                            sections: newSections,
                          },
                        });
                      }}
                    />
                  </label>
                  <ButtonGroup className={classes.inputNoLabel}>
                    <Button
                      active={tab.entityType === 'Explore'}
                      intent="none"
                      onClick={() => {
                        const newTabs = [...section.tabs];
                        newTabs[tabIndex].entityType = 'Explore';
                        const newSections = [...(selectedDemo.sections || [])];
                        newSections[sectionIndex].tabs = newTabs;

                        this.setState({
                          selectedDemo: {
                            ...selectedDemo,
                            sections: newSections,
                          },
                        });
                      }}
                      icon="chart"></Button>
                    <Button
                      active={tab.entityType === 'Analytics Hub'}
                      intent="none"
                      onClick={() => {
                        const newTabs = [...section.tabs];
                        newTabs[tabIndex].entityType = 'Analytics Hub';
                        const newSections = [...(selectedDemo.sections || [])];
                        newSections[sectionIndex].tabs = newTabs;

                        this.setState({
                          selectedDemo: {
                            ...selectedDemo,
                            sections: newSections,
                          },
                        });
                      }}
                      icon="pivot-table"></Button>
                  </ButtonGroup>
                  <Button
                    className={classes.inputNoLabel}
                    minimal
                    intent="danger"
                    onClick={() => {
                      const newTabs = section.tabs.filter((tab, index) => index !== tabIndex);
                      const newSections = [...(selectedDemo.sections || [])];
                      newSections[sectionIndex].tabs = newTabs;

                      this.setState({
                        selectedDemo: {
                          ...selectedDemo,
                          sections: newSections,
                        },
                      });
                    }}
                    icon="trash"></Button>
                </div>
              ))}
              <Button
                className={classes.createButton}
                intent="none"
                icon="plus"
                onClick={() => {
                  const newSections = [...(selectedDemo.sections || [])];
                  newSections[sectionIndex].tabs = [
                    ...section.tabs,
                    { tabName: 'New Tab', entityAuthId: '', entityType: 'Explore' },
                  ];
                  this.setState({
                    selectedDemo: {
                      ...selectedDemo,
                      sections: newSections,
                    },
                  });
                }}>
                Add Tab
              </Button>
            </div>
          ))}
        </div>
        <Button
          className={classes.createButton}
          intent="primary"
          onClick={() => this.saveDemoState()}>
          Save Demo
        </Button>
      </>
    );
  };

  createNewDemo = () => {
    const { newDemoName, newDemoUrl } = this.state;
    post('https://embeddo-custom-demo-040db5305558.herokuapp.com/api/create-demo', {
      ...DEFAULT_DEMO,
      name: newDemoName,
      url: newDemoUrl,
    }).then((response) => {
      const newDemo = {
        _id: response.insertedId,
        ...DEFAULT_DEMO,
        name: newDemoName,
        url: newDemoUrl,
      };
      this.setState({
        currentDemos: [newDemo, ...(this.state.currentDemos || [])],
        selectedDemo: { ...newDemo },
        createNewDemoModalOpen: false,
      });
    });
  };

  saveDemoState = () => {
    const { selectedDemo } = this.state;
    if (!selectedDemo) return;

    post(
      `https://embeddo-custom-demo-040db5305558.herokuapp.com/api/update-demo/${selectedDemo._id}`,
      { ...selectedDemo },
    ).then(() => {
      const indexOfChange =
        this.state.currentDemos?.findIndex((demo) => demo._id === selectedDemo._id) || -1;
      if (indexOfChange >= 0) {
        this.state.currentDemos?.splice(indexOfChange, 1);
        this.setState({
          currentDemos: [selectedDemo, ...(this.state.currentDemos || [])],
        });
      }
    });
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardPage)),
);
