/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles';

import DemoPage, { DEFAULT_DEMO } from './DemoApp';

import { get } from '../requests';
import { DemoState } from './CreateDemoPage';
import { Spinner } from '@blueprintjs/core';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': any;
    }
  }
}

const styles = (theme: Theme) => ({
  root: {},
  loadingBody: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type Props = { isReportBuilder?: boolean } & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<{ demoId: string }> &
  WithStyles<typeof styles>;

type State = {
  selectedDemo: DemoState;
  demoStateLoading: boolean;
};

class DashboardPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const demoId = props.match.params.demoId;

    const demoStateLoading = demoId !== undefined;

    if (demoId) {
      get(
        `https://embeddo-custom-demo-040db5305558.herokuapp.com/api/get-demo/${demoId}`,
        null,
      ).then((demo) => {
        if (demo.error === 'not found') return;

        this.setState({ demoStateLoading: false, selectedDemo: demo });
      });
    }

    this.state = {
      demoStateLoading,
      selectedDemo: { ...DEFAULT_DEMO },
    };
  }

  render() {
    const { classes } = this.props;
    const { selectedDemo } = this.state;

    if (this.state.demoStateLoading) {
      return (
        <div className={classes.loadingBody}>
          <Spinner size={100} />
        </div>
      );
    }

    return <DemoPage demo={selectedDemo} />;
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardPage)),
);
