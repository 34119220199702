/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';
import { Theme } from '@material-ui/core/styles';

import HomePage from 'pages/homePage';

import { ROUTES } from 'constants/routes';

import LoadingSpinner from 'images/loading_spinner.gif';

import 'index.scss';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-resizable/css/styles.css';
import CreateDemoPage from 'pages/CreateDemoPage';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      fontFamily: "'Inter', sans-serif",
    },
    loadingRoot: {
      height: '100vh',
      perspective: '1px',
      overflowY: 'auto',
    },
    loadingPage: {
      backgroundColor: theme.palette.black100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    loadingSpinner: {
      width: 75,
      zIndex: 2,
    },
  });

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

class Routes extends Component<Props> {
  state = {
    loading: false,
  };

  render() {
    const { classes } = this.props;

    return this.state.loading ? (
      <div className={classes.loadingRoot}>
        <div className={classes.loadingPage}>
          <img className={classes.loadingSpinner} src={LoadingSpinner} alt="loading spinner" />
        </div>
      </div>
    ) : (
      <Router>
        <div className={classes.root}>
          <Switch>
            <Route exact path={ROUTES.CREATE} component={CreateDemoPage} />
            <Route exact path={ROUTES.HOME} component={HomePage} />
            <Route exact path={ROUTES.CUSTOMER_EXAMPLE} component={HomePage} />
            <Route noMatch component={HomePage} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Routes));
