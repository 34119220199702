/** @format */

import React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles';
import { DemoState } from 'pages/CreateDemoPage';

export const NAV_WIDTH = 250;

export type TAB = 'Analytics' | 'Orders' | 'Reports';

const styles = (theme: Theme) => ({
  root: (props: { demo: DemoState }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: props.demo.primaryColor,
  }),
  navContainer: {
    width: NAV_WIDTH,
    height: '100%',
  },
  pageContainer: {
    width: `calc(100% - ${NAV_WIDTH}px)`,
    height: '100%',
    paddingTop: 16,
  },
  page: {
    height: '100%',
    overflow: 'auto',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    borderTopLeftRadius: 16,
  },
  header: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #FFFFFF88',
  },
  headerLogo: {
    maxHeight: 48,
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  headerText: (props: { demo: DemoState }) => ({
    fontSize: 30,
    marginLeft: 8,
    fontWeight: 800,
    color: props.demo.textPrimaryColor || 'white',
  }),
  tabsContainer: {
    padding: '8px 16px 8px 8px',
  },
  tabSectionText: (props: { demo: DemoState }) => ({
    fontSize: 12,
    fontWeight: 600,
    paddingTop: 8,
    color: props.demo.textPrimaryColor || 'white',
    paddingLeft: 8,
    paddingBottom: 4,
  }),
  tabSectionTab: (props: { demo: DemoState }) => ({
    borderRadius: 8,
    height: 40,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    color: props.demo.textPrimaryColor || 'white',
    fontWeight: 400,
    marginBottom: 4,

    '&:hover': {
      backgroundColor: props.demo.actionColor,
      color: props.demo.textActionColor,
      cursor: 'pointer',
    },

    '&.selected': {
      backgroundColor: props.demo.actionColor,
      color: props.demo.textActionColor,
    },
  }),
});

type Props = {
  demo: DemoState;
  selectedTab: string;
  onNewTabSelect: (tab: string) => void;
} & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  WithStyles<typeof styles>;

type State = {};

class PageNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, demo, onNewTabSelect, selectedTab } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.navContainer} style={{ backgroundColor: demo.primaryColor }}>
          <div className={classes.header}>
            <img className={classes.headerLogo} src={demo.logoUrl} alt="logo" />
            <div className={classes.headerText}>{demo.companyName}</div>
          </div>
          {demo.sections?.map((section, sectionIndex) => (
            <div className={classes.tabsContainer} key={`${section.sectionName}-section-nav`}>
              <div className={classes.tabSectionText}>{section.sectionName}</div>
              {section.tabs.map((tab) => (
                <div
                  className={cx(classes.tabSectionTab, { selected: selectedTab === tab.tabName })}
                  onClick={() => onNewTabSelect(tab.tabName)}
                  key={`${section.sectionName}-${tab.tabName}-${sectionIndex}`}>
                  {tab.tabName}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={classes.pageContainer}>
          <div className={classes.page}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PageNav)),
);
