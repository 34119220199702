/** @format */

export enum ROUTES {
  HOME = '/:demoId?',
  CREATE = '/create-new',
  CUSTOMER_EXAMPLE = '/test-demo',
  ALPACA = '/alpaca',
  SIMILAR_WEB = '/similar-web',
  FIDO = '/fido',
}
