/** @format */

// Auto unwrap the response as we want the data not the full headers for this simple example
const stringifyResponse = (response) => {
  if (response.status >= 400) throw new Error(response);
  return response.headers.get('Content-Type').includes('application/json')
    ? response.json()
    : response.text();
};

// use Fetch to pull some test data
// This would be in it's own util module
export const get = (url, data) =>
  fetch(`${url}`, {
    body: data,
  }).then((response) => stringifyResponse(response));

export const post = (url, data) =>
  fetch(`${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }).then((response) => stringifyResponse(response));
