/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles';

import PageNav from './shared/PageNav';

import { DemoState } from './CreateDemoPage';

export const DEFAULT_DEMO: DemoState = {
  name: 'Pied Piper',
  primaryColor: '#f8f8f8',
  actionColor: '#e8e8e8',
  textPrimaryColor: '#000',
  logoUrl: 'https://cdn.iconscout.com/icon/free/png-256/free-pied-piper-pp-3628979-3030204.png',
  companyName: 'Pied Piper',
  sections: [
    {
      sectionName: 'Analytics',
      tabs: [
        {
          tabName: 'Advanced Charting',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/iframe/QRA3v3g1Ed/803ba3cd569db4c43b21b55a8c34bc39b0ca543e9d8e32f1838d8e6599d7a1dd/production',
        },
        {
          tabName: 'Customizable Dashboard',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/iframe/BKxg2r8YLR/803ba3cd569db4c43b21b55a8c34bc39b0ca543e9d8e32f1838d8e6599d7a1dd/production',
        },
        {
          tabName: 'Report Builder',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/report-builder/iframe/z8L19X4x56/a9f627e9800a4b2bbb2cadcacb02c426ba6614bc3cb05606db44db5a14727eed/production',
        },
      ],
    },
  ],
};

export const TEST_DEMO: DemoState = {
  name: 'TEST',
  primaryColor: '#4A9942',
  actionColor: '#5B7740',
  logoUrl: 'https://cdn.iconscout.com/icon/free/png-256/free-pied-piper-pp-3628979-3030204.png',
  companyName: 'TEST',
  sections: [
    {
      sectionName: 'Analytics',
      tabs: [
        {
          tabName: 'Customizable Dashboard',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/iframe/BKxg2r8YLR/55d50290fac97b7e8bc76a390ff9ad42a4d83c1fb43f6ac1af34c001a927c18d/production',
        },
        {
          tabName: 'Advanced Charting',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/iframe/jv1OKEb1zp/55d50290fac97b7e8bc76a390ff9ad42a4d83c1fb43f6ac1af34c001a927c18d/production',
        },
        {
          tabName: 'Report Builder',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/report-builder/iframe/z8L19X4x56/a9f627e9800a4b2bbb2cadcacb02c426ba6614bc3cb05606db44db5a14727eed/production',
        },
      ],
    },
  ],
};

export const FIDO_DEMO: DemoState = {
  name: 'Pied Piper',
  primaryColor: '#2A9944',
  actionColor: '#0B7745',
  logoUrl: 'https://cdn.iconscout.com/icon/free/png-256/free-pied-piper-pp-3628979-3030204.png',
  companyName: 'Pied Piper',
  sections: [
    {
      sectionName: 'Analytics',
      tabs: [
        {
          tabName: 'Customizable Dashboard',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/iframe/2WYnZX6YZX/55d50290fac97b7e8bc76a390ff9ad42a4d83c1fb43f6ac1af34c001a927c18d/production',
        },
        {
          tabName: 'Advanced Charting',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/iframe/QgYwLXKxvR/55d50290fac97b7e8bc76a390ff9ad42a4d83c1fb43f6ac1af34c001a927c18d/production',
        },
        {
          tabName: 'Report Builder',
          entityType: 'Analytics Hub',
          entityAuthId:
            'https://app.explo.co/report-builder/iframe/z8L19X4x56/a9f627e9800a4b2bbb2cadcacb02c426ba6614bc3cb05606db44db5a14727eed/production',
        },
      ],
    },
  ],
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': any;
    }
  }
}

const styles = (theme: Theme) => ({
  root: { padding: '0 16px' },
  pageBody: { padding: '0 16px' },
});

type Props = { demo: DemoState } & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<{ demoId: string }> &
  WithStyles<typeof styles>;

type State = {
  selectedTab: string;
};

class DemoPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let firstTabName;
    if (props.demo.sections && props.demo.sections.length > 0) {
      const firstSection = props.demo.sections[0];

      if (firstSection.tabs.length > 0) {
        firstTabName = firstSection.tabs[0].tabName;
      }
    }

    this.state = {
      selectedTab: firstTabName ?? 'Analytics',
    };
  }

  render() {
    const { demo } = this.props;

    return (
      <PageNav
        demo={demo}
        selectedTab={this.state.selectedTab}
        onNewTabSelect={(tab) => {
          this.setState({ selectedTab: tab });
          setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
        }}>
        {this.renderTabPage()}
      </PageNav>
    );
  }

  renderTabPage = () => {
    const { selectedTab } = this.state;
    const { demo } = this.props;

    return (
      <div>
        {demo.sections?.map((section) =>
          section.tabs.map(
            (tab) =>
              selectedTab === tab.tabName && (
                <div style={{ display: 'block' }} key={`${tab.tabName}-tab-content`}>
                  {tab.entityType === 'Explore' ? (
                    <explo-dashboard
                      dash-user-group-token={tab.entityAuthId}
                      environment="production"
                    />
                  ) : (
                    <iframe
                      src={tab.entityAuthId}
                      style={{ width: '100%', border: 'none', height: 'calc(100vh - 20px)' }}
                      title="woo"></iframe>
                  )}
                </div>
              ),
          ),
        )}
      </div>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DemoPage)),
);
